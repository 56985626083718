<template>
  <div class="container-fluid contentTop">
    <div class="container">

      <div class="row">
        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 minTop">
          <img :src="logo1" class="img-responsive center-block" />
        </div>
        <div class="col-lg-2 col-md-2 col-sm-2 col-xs-12"></div>
        <div class="col-lg-8 col-md-8 col-sm-8 col-xs-12 kuang">
          <dd v-show="resultss == 1">
            <li class="kuangTop">教师查询</li>
            <li class="bl-form-group" style="margin-top:5%">
              <div class="labels">姓名</div>
              <el-input v-model="data.name" placeholder="请输入姓名" style="width:60%" size="small"></el-input>
            </li>
            <li class="bl-form-group" >
              <div class="labels">证件</div>
              <el-input v-model="data.id_card" placeholder="请输入身份证号" style="width:60%;" size="small"></el-input>
            </li>
            <li class="bl-form-group" style="text-align:center;padding-bottom:5%">
               <el-button size="mini" @click="submit">确认查询</el-button>
            </li>
            <li class="kuangTops">使用过程中有任何问题请与我们联系:010-57108081</li>
          </dd>
          <dd v-show="resultss == 2">
            <li class="kuangTop">查询结果</li>
            <table border="1" class="bnorder" v-for="item in zhengshu" :key="item.certificate_id">
              <tr>
                <td >姓名</td>
                <td colspan="3">{{item.name}}</td>
              </tr>
              <tr>
                <td>性别</td>
                <td width="30%">{{item.gender}}</td>
                <td>身份证号</td>
                <td>{{item.id_card}}</td>
              </tr>
              <tr>
                <td>专业</td>
                <td width="30%">{{item.art_category}}</td>
                <td>级别</td>
                <td>{{item.grade}}</td>
              </tr>
              <tr>
                <td>证书编号</td>
                <td width="30%">{{item.certificate_number}}</td>
                <td>发证日期</td>
                <td>{{item.certificates_time}}</td>
              </tr>
            </table>
            <li class="kuangTops">使用过程中有任何问题请与我们联系:010-57108081</li>
          </dd>
          <dd v-show="resultss == 3">
            <li class="kuangTop">查询结果</li>
            <li class="kuangTopHitht">未找到结果</li>
            <li class="kuangTops">使用过程中有任何问题请与我们联系:010-57108081</li>
          </dd>
        </div>
        <div class="col-lg-2 col-md-2 col-sm-2 col-xs-12 bootom"></div>
      </div>

    </div>
  </div>
  
</template>

<script>
import { post } from "@/http/api";
import Top from "./top.vue";
import bottom from "./bottom.vue";
import erwei from "@/assets/erwei.png";
import logo1 from "@/assets/logo1.png";

export default {
  name: "newsInfo",
  components: {
    Top,
    bottom,
    erwei,
    logo1,
  },
  data() {
    return {
      erwei,
      logo1,
      data: {
        name: "",
        id_card:'',
        title:'',
        query_type:3,
        teachers_type:1,
      },
      resultss:1,
      zhengshu:{},
      oldzhengshu:{},
    };
  },

  created(){

  },
  methods: {
    submit(){
      let data = this.data;
        if(!data.name || !data.id_card){
          this.$message({
            message: '请正确填写姓名或证件！',
            type: 'warning'
          });
          return false;
        }
        const qs = require("qs");
        post( "Certificate/getCertificate",qs.stringify(data)).then((res) => {
          if(res.code == 200){
            this.resultss = 2;
            this.zhengshu = res.certificateInfo;
          }else if(res.code == 2001){
            this.$message({
              message: res.msg,
              type: 'warning'
            });
          }else{
            this.resultss = 3;
          }
        });
    },
  },
  mounted(){
    window.scrollTo(0,0)
  },

};
</script> 
<style scoped>
@media (max-width: 768px) {
 .contentTop {
    background: url(../assets/bg.jpg) no-repeat top center #0066cb;
    background-size: 100%;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 12px;
    color: #222222;
  }
  .minTop {
    padding-top: 11.1%;
  }
  .kuang{
    padding-left: 0px;
    padding-right: 0px;
  }
  .kuang dd {
    border: 1px solid #d1dbe5;
    margin-top: 5%;
    margin-bottom: 100%;
    background: #d1dbe5;
  }
  .kuang li {
    padding: 0px;
    list-style: none;
  }
  .kuangTop {
    height: 41px;
    background: rgb(20, 106, 163);
    list-style: none;
    line-height: 41px;
    color: #fff;
    font-size: 20px;
    text-align: center;
  }
  .kuangTop1 {
    text-align: center;
  }
  .bootom {
    height: 0px;
  }
  .bl-form-group {
    display: flex;
    justify-content: center;
    margin-top: 15px;
  }
  .nian {
    text-align: center;
    margin-top: 10px;
  }
  .labels {
    margin-top: 8px;
    padding-right: 5px;
  }
  .kuangTops{
    height: 30px;
    background: rgb(20, 106, 163);
    list-style: none;
    line-height:30px;
    color: #fff;
    font-size: 12px;
    text-align: center;
  }

  /* 表单 */
  .bnorder{
    width: 100%;
    margin-bottom: 3%;
  }
  .bnorder tr{
    height:40px;
    text-align: center;
  }
  .kuangTopHitht{
    height: 150px;
    text-align: center;
    margin-top: 10%;
    font-size: 15px;
  }

}

@media (min-width: 768px) {
 .contentTop {
    background: url(../assets/bg.jpg) no-repeat top center #0066cb;
    background-size: 100%;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 12px;
    color: #222222;
  }
  .minTop {
    padding-top: 11.1%;
  }
  .kuang dd {
    border: 1px solid #d1dbe5;
    margin-top: 5%;
    margin-bottom: 100%;
    background: #d1dbe5;
  }
  .kuang li {
    padding: 0px;
    list-style: none;
  }
  .kuangTop {
    height: 41px;
    background: rgb(20, 106, 163);
    list-style: none;
    line-height: 41px;
    color: #fff;
    font-size: 20px;
    text-align: center;
  }
  .kuangTop1 {
    text-align: center;
  }
  .bootom {
    height: 0px;
  }
  .bl-form-group {
    display: flex;
    justify-content: center;
    margin-top: 15px;
  }
  .nian {
    text-align: center;
    margin-top: 10px;
  }
  .labels {
    margin-top: 8px;
    padding-right: 5px;
  }
  .kuangTops{
    height: 30px;
    background: rgb(20, 106, 163);
    list-style: none;
    line-height:30px;
    color: #fff;
    font-size: 12px;
    text-align: center;
  }

  /* 表单 */
  .bnorder{
    width: 100%;
    margin-bottom: 3%;
  }
  .bnorder tr{
    height:40px;
    text-align: center;
  }
  .kuangTopHitht{
    height: 150px;
    text-align: center;
    margin-top: 10%;
    font-size: 15px;
  }

}

@media (min-width: 992px) {
 .contentTop {
    background: url(../assets/bg.jpg) no-repeat top center #0066cb;
    background-size: 100%;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 12px;
    color: #222222;
  }
  .minTop {
    padding-top: 11.1%;
  }
  .kuang dd {
    border: 1px solid #d1dbe5;
    margin-top: 5%;
    margin-bottom: 100%;
    background: #d1dbe5;
  }
  .kuang li {
    padding: 0px;
    list-style: none;
  }
  .kuangTop {
    height: 41px;
    background: rgb(20, 106, 163);
    list-style: none;
    line-height: 41px;
    color: #fff;
    font-size: 20px;
    text-align: center;
  }
  .kuangTop1 {
    text-align: center;
  }
  .bootom {
    height: 0px;
  }
  .bl-form-group {
    display: flex;
    justify-content: center;
    margin-top: 15px;
  }
  .nian {
    text-align: center;
    margin-top: 10px;
  }
  .labels {
    margin-top: 8px;
    padding-right: 5px;
  }
  .kuangTops{
    height: 30px;
    background: rgb(20, 106, 163);
    list-style: none;
    line-height:30px;
    color: #fff;
    font-size: 12px;
    text-align: center;
  }

  /* 表单 */
  .bnorder{
    width: 100%;
    margin-bottom: 3%;
  }
  .bnorder tr{
    height:40px;
    text-align: center;
  }
  .kuangTopHitht{
    height: 150px;
    text-align: center;
    margin-top: 10%;
    font-size: 15px;
  }
}

@media (min-width: 1200px) {
  .contentTop {
    background: url(../assets/bg.jpg) no-repeat top center #0066cb;
    background-size: 100%;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 12px;
    color: #222222;
  }
  .minTop {
    padding-top: 11.1%;
  }
  .kuang dd {
    border: 1px solid #d1dbe5;
    margin-top: 5%;
    margin-bottom: 100%;
    background: #d1dbe5;
  }
  .kuang li {
    padding: 0px;
    list-style: none;
  }
  .kuangTop {
    height: 41px;
    background: rgb(20, 106, 163);
    list-style: none;
    line-height: 41px;
    color: #fff;
    font-size: 20px;
    text-align: center;
  }
  .kuangTop1 {
    text-align: center;
  }
  .bootom {
    height: 0px;
  }
  .bl-form-group {
    display: flex;
    justify-content: center;
    margin-top: 15px;
  }
  .nian {
    text-align: center;
    margin-top: 10px;
  }
  .labels {
    margin-top: 8px;
    padding-right: 5px;
  }
  .kuangTops{
    height: 30px;
    background: rgb(20, 106, 163);
    list-style: none;
    line-height:30px;
    color: #fff;
    font-size: 12px;
    text-align: center;
  }

  /* 表单 */
  .bnorder{
    width: 100%;
    margin-bottom: 3%;
  }
  .bnorder tr{
    height:40px;
    text-align: center;
  }
  .kuangTopHitht{
    height: 150px;
    text-align: center;
    margin-top: 10%;
    font-size: 15px;
  }

}
</style>